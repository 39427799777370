import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"


const ResearchPage = ({ data }) => (
  
    <Layout>
      <SiteMetadata title="Research" description="Research page of Pranay Thangeda" />

      <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Research
            </h1>
            <div className="mt-4 leading-loose">
            My research focuses on the design and analysis of algorithms for efficient learning and planning 
            in sequential decision-making problems under uncertainty. Most of my work is motivated by real-world problems 
            (e.g., navigation of planetary rovers in unknown environments, optimal routing for resource-constrained systems 
            in stochastic networks) and involves using mathematical tools from the areas of control theory, optimization, motion planning, 
            and machine learning. 
            </div>
      </div>


      </div>
    </Layout>
    
)



export default ResearchPage
